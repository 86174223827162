import React from 'react';
import Layout from '../components/Layout';
import SEO from '../components/SEO';
import Hero2 from '../components/Hero2';

export default function success() {
  return (
    <Layout footer2>
      <SEO title="Form submitted!" />
      <Hero2
        title1="Success"
        title2="Form Submitted!"
        para="Please check that you received our confirmation email (check spam folder as well). We'll contact you shortly."
      />
    </Layout>
  );
}
