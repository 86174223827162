import React from 'react';
import { Link } from 'gatsby';

export default function Hero2({ title1, title2, para }) {
  return (
    <>
      <div className="hero hero-2">
        <div className="wrapper">
          <div className="hero-texts">
            <h1>
              <div className="main-text title-1">
                <div className="inner">{title1}</div>
              </div>
              <div className="main-text title-2">
                <div className="inner">{title2}</div>
              </div>
            </h1>

            {para && <p className="info split-lines">{para}</p>}

            <Link to="/" className="link">
              Back to homepage
            </Link>
          </div>
        </div>
      </div>
    </>
  );
}
